const carouselDealsData = {
  colorado: [
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-1.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-2.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-3.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-4.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-5.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-6.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-7.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-8.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-9.png",
    },
    {
      imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-10.png",
    },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-11.png",
    // },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-12.png",
    // },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-13.png",
    // },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-14.png",
    // },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-15.png",
    // },
    // {
    //   imgUrl: "/images/deals/coloradoDeals/weekly-update/coupon-16.png",
    // },
  ],

  michigan: [
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-1.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-2.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-3.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-4.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-5.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-6.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-7.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-8.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-9.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-10.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-11.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-12.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-13.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-14.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-15.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-16.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-17.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-18.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-19.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-20.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-21.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-22.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-23.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-24.png",
    },
    {
      imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-25.png",
    },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-26.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-27.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-28.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-29.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-30.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-31.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-32.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-33.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-34.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-35.png",
    // },
        // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-36.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-37.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-38.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-39.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-40.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-41.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-42.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-43.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-44.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-45.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-46.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-47.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-48.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-49.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-50.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-51.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-52.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-53.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-54.png",
    // },
    // {
    //   imgUrl: "/images/deals/michiganDeals/weekly-update/coupon-55.png",
    // },
  ],
};

export default carouselDealsData;
